const initialState = {
  notificationData: null,
  notificationBell: false,
  isHardrefresh: false,
};

const NotificationReducer = (state = initialState, action) => {
  const { type, payload, token, notificationBell, isHardrefresh } = action;

  switch (type) {
    case 'HARD_REFRESH':
      return {
        ...state,
        isHardrefresh,
      };
    case 'NOTIFICATION':
      return {
        ...state,
        notificationData: payload,
        notificationBell,
      };
    case 'READNOTIFICATION':
      return {
        ...state,
        notificationBell,
        sidebarKey: payload,
      };
    default:
      return state;
  }
};

export default NotificationReducer;
