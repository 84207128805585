import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';
import './styles/main.scss';
// import ServiceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();
// ServiceWorker();
