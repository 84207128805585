import ApiUtils from '../../helpers/APIUtils';

const api = new ApiUtils();

export const getNotifications =
  ({ notificationBell = false, token = null, page = '1', limit = '10' }) =>
  async (dispatch, getState) => {
    try {
      let data = null;
      const idToken = localStorage.getItem('id_token');
      const authToken = getState().auth.token;
      const { isHardrefresh } = getState().notification;
      const values = {};
      if (!authToken) values.Authorization = `Bearer ${token}`;

      if (isHardrefresh) dispatch({ type: 'HARD_REFRESH', isHardrefresh: false });

      if (!idToken) return dispatch({ type: 'NEW_USER' });

      data = await api.getNotifications({
        params: {
          page,
          limit,
        },
        values,
      });

      dispatch({
        type: 'NOTIFICATION',
        payload: data || data?.data?.data,
        notificationBell,
      });
      return true;
    } catch (e) {
      return false;
    }
  };
