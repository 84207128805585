import ApiUtils from '../../helpers/APIUtils';
import { onLogoutDistroyToken, GetLiveNotification } from '../../firebaseinit';

const api = new ApiUtils();

const TOKEN_NAME = process.env.REACT_APP_TOKEN_NAME;

export const loadUser = fcmToken => async (dispatch, getState) => {
  try {
    const token = localStorage.getItem('id_token');
    const language =
      localStorage.getItem('i18nextLng') === 'en-GB' ? 'en' : localStorage.getItem('i18nextLng');
    let notificationToken = localStorage.getItem('fcmToken');

    if (!fcmToken && notificationToken === 'null') notificationToken = undefined;

    if (!token) return dispatch({ type: 'NEW_USER' });

    const res = await api.loadUser(
      {
        fcm: fcmToken || notificationToken,
        platform: 1,
        language,
      },
      { Authorization: `Bearer ${token}` }
    );

    dispatch({
      type: 'EXISTING_USER',
      payload: res.data,
      token,
    });

    dispatch({
      type: 'HARD_REFRESH',
      isHardrefresh: true,
    });

    GetLiveNotification(dispatch, res.data.data._id, token, getState);

    return true;
  } catch (err) {
    dispatch({ type: 'AUTH_FAILED' });
    return false;
  }
};

export const changeLanguage = data => async dispatch => {
  try {
    dispatch({
      type: 'CHANGE_LANGUAGE',
      payload: data,
    });
    localStorage.setItem('language', data);
    return true;
  } catch (err) {
    // console.log('CATCH ERROR', err);
    return false;
  }
};

export const login = (data, fcmToken) => async dispatch => {
  try {
    const res = await api.login(data);
    // after successfully login, you will get token on it
    localStorage.setItem('id_token', res?.data?.token);

    await dispatch(loadUser(fcmToken));
    dispatch({
      type: 'CHANGESIDEBAR',
      payload: '/',
    });

    return true;
  } catch (err) {
    // console.log('error');
    dispatch({ type: 'AUTH_FAILED' });
    return false;
  }
};

export const logout = () => async dispatch => {
  try {
    const res = await api.logout();
    await onLogoutDistroyToken();
    localStorage.removeItem(TOKEN_NAME);
    dispatch({ type: 'LOGOUT' });

    return true;
  } catch (err) {
    // console.log('CATCH ERROR', err);
    return false;
  }
};
