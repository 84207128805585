/* eslint-disable no-new */
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { doc, onSnapshot, getFirestore } from 'firebase/firestore';
import { getMessaging, getToken, onMessage, deleteToken } from 'firebase/messaging';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getNotifications } from './redux/actions/notificationActions';

// const history = useHistory();
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBBAA00UvxsTtVdpU616HX3UrbAcM1uHNM',
  authDomain: 'jail-connect.firebaseapp.com',
  projectId: 'jail-connect',
  storageBucket: 'jail-connect.appspot.com',
  messagingSenderId: '776206377742',
  appId: '1:776206377742:web:fa6d876dd63c5a6f6eed5f',
  measurementId: 'G-QKCHSJRB79',
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const publicKey = process.env.REACT_APP_FIREBASE_KEY;
const messaging = getMessaging(app);
const db = getFirestore(app);

export const GetToken = async () => {
  let currentToken = '';

  try {
    currentToken = await getToken(messaging, { vapidKey: publicKey });
    if (currentToken) {
      // setTokenFound(true);
      localStorage.setItem('fcmToken', currentToken);
      return currentToken;
    }
  } catch (error) {
    // console.log("Error", error);
  }
  localStorage.setItem('fcmToken', null);
  return null;
};

export const GetLiveNotification = (dispatch, id, token, getState) => {
  const unsub = onSnapshot(doc(db, 'live-notification', id), async data => {
    const { isHardrefresh } = getState().notification;
    await dispatch(
      getNotifications({
        notificationBell: !isHardrefresh,
        token,
      })
    );
  });
};

export const onMessagingListener = dispatch => {
  new Promise(resolve => {
    onMessage(messaging, async payload => {
      // console.log('yooo', payload);
      await dispatch(getNotifications({ notificationBell: true }));
      resolve(payload);
    });
  });
};

export const onLogoutDistroyToken = () => {
  // console.log("in");
  deleteToken(messaging, payload => {
    // console.log("deleted", payload);
  });
};
