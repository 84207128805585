export const en = {
  Dashboard: 'Dashboard',
  Lets_Connect_us: 'Lets Connect, us',
  Lorem: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  Login: 'Login',
  Login_to_your_account: 'Login to your account',
  Enter_your_email_and_password_to_connect: 'Enter your email and password to connect',
  with_Jail_connect: 'with Jail connect',
  Email_address: 'Email address',
  Email_Validation: 'Please enter a valid email address',
  Password: 'Password',
  Remember_me: 'Remember me',
  Forgot_password: 'Forgot password',
  Send: 'Send',
  Sign_In: 'Sign In',
  Already_have_an_account: 'Already have an account',
  Forgot_your_password: 'Forgot your password',
  Order_Management: 'Order Management',
  Category_Management: 'Category Management',
  Product_Management: 'Product Management',
  Edit_profile: 'Edit profile',
  Change_Password: 'Change Password',
  Notifications: 'Notifications',
  About_us: 'About us',
  Terms_Conditions: 'Terms & Conditions',
  Privacy_Policy: 'Privacy Policy',
  Logout: 'Logout',
  Profile: 'Profile',
  delivered: 'delivered',
  Orders_Delivered: 'Orders Delivered',
  Order_Delivered_At: 'Order Delivered At',
  ordered: 'ordered',
  Orders_Pending: 'Orders Pending',
  Expected_Delivery: 'Expected Delivery',
  Product_Name: 'Product Name',
  Product_Category: 'Product Category',
  Quantity: 'Quantity',
  Price: 'Price',
  Order_Id: 'Order Id',
  Inmate: 'Inmate',
  Status: 'Status',
  Order_Placed_At: 'Order Placed At',
  Search: 'Search',
  You_can_search_Orders_using_Order_Id_or_Inmate_Name:
    'You can search Orders using Order Id or Inmate Name',
  Reset: 'Reset',
  Total_Count: 'Total Count',
  Close: 'Close',
  Details: 'Details',
  Image: 'Image',
  Available_Qty: 'Available Qty',
  Category: 'Category',
  Action_By: 'Action By',
  Created_At: 'Created At',
  Updated_At: 'Updated At',
  Action: 'Action',
  Delete: 'Delete',
  Edit: 'Edit',
  View: 'View',
  You_can_search_using_Product_Name_or_Category_Name:
    'You can search using Product Name or Category Name',
  Add_Product: 'Add Product',
  View_Product: 'View Product',
  Stock: 'Stock',
  Description: 'Description',
  Delete_Category: 'Delete Category',
  Are_you_sure_want_to_delete: 'Are you sure, want to delete',
  Category_Name: 'Category Name',
  Total_Products: 'Total Products',
  You_can_search_using_Category_Name: 'You can search using Category Name',
  Add_Category: 'Add Category',
  View_Category: 'View Category',
  Products: 'Products',
  Amount: 'Amount',
  Inamate_name: 'Inmate name',
  Total_earnings: 'Total earnings',
  Pending_orders: 'Pending orders',
  Total_orders: 'Total orders',
  Todays_earnings: 'Today’s earnings',
  View_Less: 'View Less',
  View_More: 'View More',
  First_Name: 'First Name',
  Last_Name: 'Last Name',
  Phone_Number: 'Phone Number',
  save: 'save',
  Password_not_matched: 'Password not matched',
  Change_your_password: 'Change your password',
  Current_password: 'Current password',
  New_password: 'New password',
  Confirm_new_password: 'Confirm new password',
  Change: 'Change',
  Done: 'Done',
  Edit_Category: 'Edit Category',
  Total_Quantity: 'Total Quantity',
  Product_Description: 'Product Description',
  Upload_Image: 'Upload Image',
  Reset_Password: 'Reset Password',
  Reset_your_password: 'Reset your password',
  Reset_your_password_to_connect: 'Reset your password to connect',
  Confirm_Password: 'Confirm Password',
  Your_entered_passwords_does_not_match: 'New password & Confirm password does not match',
  Edit_Product: 'Edit Product',
  Delete_Product: 'Delete Product',
  Canceled_Orders: 'Orders Canceled',
  Canceled_at: 'Canceled At',
  start_date: 'Start date',
  end_date: 'End date',
};
