/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Menu, Button, Dropdown, notification } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/authActions';

const { SubMenu } = Menu;
const { Sider } = Layout;

const Sidebar = () => {
  const history = useHistory();
  const { pathname } = useLocation('');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const initalKey = useSelector(state => state.auth.sidebarKey);
  const [sidebarKey, setSidebarKey] = useState(initalKey);

  const handleChange = async e => {
    console.log(e);
    await dispatch({
      type: 'CHANGESIDEBAR',
      payload: e,
    });
    console.log(e);
    setSidebarKey(e?.key);
  };
  useEffect(async () => {
    await dispatch({
      type: 'CHANGESIDEBAR',
      payload: pathname,
    });
    setSidebarKey(pathname);
  }, [pathname]);

  const handleLogout = async () => {
    const res = await dispatch(logout());

    if (res) {
      notification.success({ description: 'Logout Successfully' });
      history.push('/login');
    }
  };

  const raceMenu = (
    <Menu>
      <Menu.Item>
        <a rel="noopener noreferrer" href="/edit-profile">
          Edit Profile
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="/change-password" rel="noopener noreferrer">
          Change Password
        </a>
      </Menu.Item>
      <Menu.Item>
        <a rel="noopener noreferrer" href="/login">
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );
  return (
    <Sider collapsible collapsedWidth="0" width="300" className="left-sidebar">
      <div className="logo sidebar-top full-width">
        <Link
          to="/"
          role="presentation"
          onClick={() =>
            dispatch({
              type: 'CHANGESIDEBAR',
              payload: '/',
            })
          }
        >
          <img className="sidebar-logo" src="images/top-logo.svg" alt="logo" />
        </Link>
        <div className="full-width profile-section sidebar-profile-section flex-center">
          <div className="my-profile-menu">
            <div id="profile_dd" className="default-dropdown top-arrow">
              <Dropdown
                overlay={raceMenu}
                placement="bottomLeft"
                getPopupContainer={() => document.getElementById('profile_dd')}
              >
                <Button className="race">
                  <i className="fa fa-angle-down" aria-hidden="true" />
                </Button>
              </Dropdown>
            </div>
          </div>
          <div className="title">{user?.data?.fullName}</div>
          <div className="profile-image">
            <img src={user?.data?.avatarUrl} alt="" style={{ borderRadius: '50%' }} />
          </div>
        </div>
      </div>
      <Menu mode="inline" selectedKeys={[sidebarKey]} onClick={e => handleChange(e)}>
        <Menu.Item key={sidebarKey === '/' ? '/' : '/dashboard'}>
          {/* <ScrollAnimation animateOnce className="full-width" animateIn="fadeInLeft" delay={500}> */}
          <Link to="/dashboard">
            <img className="not-hover-show" src="images/sidebar-dashboard-icon.svg" alt="" />
            <img className="hover-show" src="images/sidebar-dashboard-hover-icon.svg" alt="" />
            {t('Dashboard')}
            <i className="fa fa-angle-right" aria-hidden="true" />
          </Link>
          {/* </ScrollAnimation> */}
        </Menu.Item>
        <Menu.Item key="/order-management">
          {/* <ScrollAnimation animateOnce className="full-width" animateIn="fadeInLeft" delay={900}> */}
          <Link to="/order-management">
            <img className="not-hover-show" src="images/sidebar-order-managment-icon.svg" alt="" />
            <img
              className="hover-show"
              src="images/sidebar-order-managment-hover-icon.svg"
              alt=""
            />
            {t('Order_Management')}
            <i className="fa fa-angle-right" aria-hidden="true" />
          </Link>
          {/* </ScrollAnimation> */}
        </Menu.Item>
        <Menu.Item key="/category-management">
          {/* <ScrollAnimation animateOnce className="full-width" animateIn="fadeInLeft" delay={1300}> */}
          <Link to="/category-management">
            <img
              className="not-hover-show"
              src="images/sidebar-category-managment-icon.svg"
              alt=""
            />
            <img
              className="hover-show"
              src="images/sidebar-category-managment-hover-icon.svg"
              alt=""
            />
            {t('Category_Management')}
            <i className="fa fa-angle-right" aria-hidden="true" />
          </Link>
          {/* </ScrollAnimation> */}
        </Menu.Item>
        <Menu.Item key="/product-management">
          {/* <ScrollAnimation animateOnce className="full-width" animateIn="fadeInLeft" delay={1700}> */}
          <Link to="/product-management">
            <img
              className="not-hover-show"
              src="images/sidebar-product-managment-icon.svg"
              alt=""
            />
            <img
              className="hover-show"
              src="images/sidebar-product-managment-hover-icon.svg"
              alt=""
            />
            {t('Product_Management')}
            <i className="fa fa-angle-right" aria-hidden="true" />
          </Link>
          {/* </ScrollAnimation> */}
        </Menu.Item>
        <Menu.Item className="mobile-profile-menu">
          <Link to="/" className="profile-mobile-icon">
            <img className="not-hover-show" src="images/sidebar-profile-icon.svg" alt="" />
            <img className="hover-show" src="images/sidebar-profile-hover-icon.svg" alt="" />
            <i className="fa fa-angle-right" aria-hidden="true" />
          </Link>
          <SubMenu key="7" title={t('Profile')}>
            <Menu.ItemGroup>
              <Menu.Item key="1">
                <Link to="/edit-profile">{t('Edit_profile')}</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to="/change-password">{t('Change_Password')}</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to="/notification">{t('Notifications')}</Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to="/about-us"> {t('About_us')}</Link>
              </Menu.Item>
              <Menu.Item key="5">
                <Link to="/terms-conditions">{t('Terms_Conditions')}</Link>
              </Menu.Item>
              <Menu.Item key="6">
                <Link to="/privacy-policy">{t('Privacy_Policy')}</Link>
              </Menu.Item>
              <Menu.Item key="7" onClick={handleLogout}>
                <Link to="#">{t('Logout')}</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </SubMenu>
        </Menu.Item>
        <Menu.Item key="/edit-profile" className="mobile-hide">
          {/* <ScrollAnimation animateOnce className="full-width" animateIn="fadeInLeft" delay={2100}> */}
          <Link to="/edit-profile">
            <img className="not-hover-show" src="images/sidebar-profile-icon.svg" alt="" />
            <img className="hover-show" src="images/sidebar-profile-hover-icon.svg" alt="" />
            {t('Profile')}
            <i className="fa fa-angle-right" aria-hidden="true" />
          </Link>
          {/* </ScrollAnimation> */}
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
