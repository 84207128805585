export const es = {
  Dashboard: 'Tablero de mandos',
  Lets_Connect_us: 'Conectémonos, nosotros',
  Lorem:
    'Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica.',
  Login: 'Acceso',
  Login_to_your_account: 'Ingrese a su cuenta',
  Enter_your_email_and_password_to_connect:
    'Ingrese su correo electrónico y contraseña para conectarse',
  with_Jail_connect: 'con Jail conectar',
  Email_address: 'Dirección de correo electrónico',
  Email_Validation: 'Por favor, introduce una dirección de correo electrónico válida',
  Password: 'Contraseña',
  Remember_me: 'Recuérdame',
  Forgot_password: 'Se te olvidó tu contraseña',
  Send: 'Enviar',
  Sign_In: 'Iniciar sesión',
  Forgot_your_password: 'Olvidaste tu contraseña',
  Order_Management: 'Gestión de pedidos',
  Category_Management: 'Gestión de categorías',
  Product_Management: 'Gestión de productos',
  Edit_profile: 'Editar perfil',
  Change_Password: 'Cambiar la contraseña',
  Notifications: 'Notificaciones',
  About_us: 'Sobre nosotros',
  Terms_Conditions: 'Términos y condiciones',
  Privacy_Policy: 'Política de privacidad',
  Logout: 'Cerrar sesión',
  Profile: 'Perfil',
  Already_have_an_account: 'Ya tienes una cuenta',
  delivered: 'entregado',
  Orders_Delivered: 'Pedidos entregados',
  Order_Delivered_At: 'Orden Entregada en',
  ordered: 'ordenado',
  Orders_Pending: 'Pedidos Pendientes',
  Expected_Delivery: 'Entrega esperada',
  Product_Name: 'nombre del producto',
  Product_Category: 'categoria de producto',
  Quantity: 'Cantidad',
  Price: 'Precio',
  Order_Id: 'Identificación de la Orden',
  Inmate: 'Preso',
  Status: 'Estatus',
  Order_Placed_At: 'Pedido realizado en',
  Search: 'Búsqueda',
  You_can_search_Orders_using_Order_Id_or_Inmate_Name:
    'Puede buscar pedidos usando ID de pedido o nombre del recluso',
  Reset: 'Reajustar',
  Total_Count: 'Cuenta total',
  Close: 'Cerrarse',
  Details: 'Detalles',
  Image: 'Imagen',
  Available_Qty: 'Cant. Disponible',
  Category: 'Categoría',
  Action_By: 'Acción por',
  Created_At: 'Creado en',
  Updated_At: 'Actualizado en',
  Action: 'Acción',
  Delete: 'Borrar',
  Edit: 'Editar',
  View: 'Vista',
  You_can_search_using_Product_Name_or_Category_Name:
    'Puede buscar usando el nombre del producto o el nombre de la categoría',
  Add_Product: 'Añadir Producto',
  View_Product: 'Ver el producto',
  Stock: 'Existencias',
  Description: 'Descripción',
  Delete_Category: 'Eliminar categoría',
  Are_you_sure_want_to_delete: 'Estas seguro quieres eliminar',
  Category_Name: 'Nombre de categoría',
  Total_Products: 'Productos totales',
  You_can_search_using_Category_Name: 'Puede buscar usando Nombre de categoría',
  Add_Category: 'añadir categoría',
  View_Category: 'Ver Categoría',
  Products: 'Productos',
  Amount: 'Monto',
  Inamate_name: 'Nombre de inamate',
  Total_earnings: 'Ganancias Totales',
  Pending_orders: 'Ordenes pendientes',
  Total_orders: 'Pedidos totales',
  Todays_earnings: 'Las ganancias de hoy',
  View_Less: 'Ver menos',
  View_More: 'Ver más',
  First_Name: 'Primer nombre',
  Last_Name: 'Apellido',
  Phone_Number: 'Número de teléfono',
  save: 'salvar',
  Password_not_matched: 'Contraseña no coincidente',
  Change_your_password: 'cambia tu contraseña',
  Current_password: 'Contraseña actual',
  New_password: 'Nueva contraseña',
  Confirm_new_password: 'Confirmar nueva contraseña',
  Change: 'Cambio',
  Done: 'Hecho',
  Edit_Category: 'Editar categoria',
  Total_Quantity: 'Cantidad total',
  Product_Description: 'Descripción del producto',
  Upload_Image: 'Cargar imagen',
  Reset_Password: 'Restablecer la contraseña',
  Reset_your_password: 'Restablecer su contraseña',
  Reset_your_password_to_connect: 'Restablece tu contraseña para conectarte',
  Confirm_Password: 'Confirmar contraseña',
  Your_entered_passwords_does_not_match: 'Nueva contraseña y Confirmar contraseña no coincide',
  Edit_Product: 'Editar Producto',
  Canceled_Orders: 'Órdenes Canceladas',
  Canceled_at: 'Cancelado a las',
  Delete_Product: 'Eliminar producto',
  start_date: 'Fecha de inicio',
  end_date: 'Fecha final',
};
