const editProfileRoutes = [
  '/change-password',
  '/notification',
  '/about-us',
  '/terms-conditions',
  '/privacy-policy',
];

const initialState = {
  isAuthenticated: false,
  token: null,
  user: {},
  language: 'en',
  sidebarKey: editProfileRoutes.includes(window.location.pathname)
    ? '/edit-profile'
    : window.location.pathname,
};

const AuthReducer = (state = initialState, action) => {
  const { type, payload, token, notificationBell, isHardrefresh } = action;

  switch (type) {
    case 'EXISTING_USER':
      return {
        ...state,
        isAuthenticated: true,
        token,
        user: payload,
      };
    case 'CHANGESIDEBAR':
      return {
        ...state,
        sidebarKey: payload,
      };
    case 'CHANGE_LANGUAGE':
      return {
        ...state,
        language: payload,
      };
    case 'NEW_USER':
    case 'AUTH_FAILED':
      return initialState;
    default:
      return state;
  }
};

export default AuthReducer;
